import { ref, watch, computed, reactive } from '@vue/composition-api'
import store, {globalStore} from '@/store'
import { title } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const rates = ref([])
const filteredRates = ref([])

export default function useRate() {
  // Use toast
  const toast = useToast()

  const refRateTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, stickyColumn: true },
    { key: 'baseType.name', label: 'Ride Type', sortable: true },
    { key: 'city', sortable: true },
    { key: 'insuranceRate', label: 'Insurance (%)', sortable: true },
    { key: 'ratePerKm', label: '/Km', sortable: true },
    { key: 'ratePerMin', label: '/Min', sortable: true },
    { key: 'startPrice', label: 'Base', sortable: true },
    { key: 'minimumPrice', label: 'Minimum', sortable: true },
    { key: 'waitingRate', label: 'Waiting', sortable: true },
    { key: 'airportRate', label: 'Airport', sortable: true },
    { key: 'bookingFee', label: 'Booking (%)', sortable: true },
    { key: 'surgeCharge', label: 'Surge (%)', sortable: true },
    { key: 'unionCharge', label: 'Union (%)', sortable: true },
    { key: 'actions'},
  ]
  const perPage = ref(10)
  const totalRates = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)


  const dataMeta = computed(() => {
    const localItemsCount = refRateTable.value ? refRateTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRates.value,
    }
  })

  const refetchData = () => {
    refRateTable.value.refresh()
  }
 
  const refreshData = () => {
    rates.value = []
    fetchRates()
  }

  watch([currentPage, perPage, searchQuery], () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = rates.value.filter(
    rate => rate.name.toLowerCase().includes(queryLowered))
    
    filteredRates.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalRates.value = filteredData.length
    refetchData()
  })

  function setRate(rate) {
    rates.value = rates.value.filter(x => x.id != rate.id);
    rates.value.push(rate);
    rates.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredRates.value = paginateArray(rates.value, perPage.value, currentPage.value)
  }

  function removeRate(rateId) {
    rates.value = rates.value.filter(x => x.id != rateId);
    rates.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredRates.value = paginateArray(rates.value, perPage.value, currentPage.value)
  }

  const fetchRates = (ctx) => {
    if(rates.value?.length) {
      return;
    }
    useJwt.axiosIns.get(routes.RATE)
      .then(({data}) => {
        rates.value = data.sort((a, b) => a.updatedAt - b.updatedAt);
        filteredRates.value = paginateArray(rates.value, perPage.value, currentPage.value)
        totalRates.value = data.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching rates',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchRates,
    tableColumns,
    perPage,
    currentPage,
    totalRates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRateTable,

    setRate,
    removeRate,
    refetchData,
    refreshData,
    rates: computed(() => filteredRates.value)
  }
}
