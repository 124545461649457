import { ref, watch, computed, reactive } from '@vue/composition-api'
import store, {globalStore} from '@/store'
import { title } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const baseRideTypes = ref([])
const filteredBaseRideTypes = ref([])

export default function useBaseRideType() {
  // Use toast
  const toast = useToast()

  const refBaseRideTypeTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'name', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalRideTypes = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)


  const dataMeta = computed(() => {
    const localItemsCount = refBaseRideTypeTable.value ? refBaseRideTypeTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRideTypes.value,
    }
  })

  const refetchData = () => {
    refBaseRideTypeTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = baseRideTypes.value.filter(
    baseRideType => baseRideType.name.toLowerCase().includes(queryLowered))
    
    filteredBaseRideTypes.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalRideTypes.value = filteredData.length
    refetchData()
  })

  function setBaseRideType(baseRideType) {
    baseRideTypes.value = baseRideTypes.value.filter(x => x.id != baseRideType.id);
    baseRideTypes.value.push(baseRideType);
    baseRideTypes.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredBaseRideTypes.value = paginateArray(baseRideTypes.value, perPage.value, currentPage.value)
  }

  function removeBaseRideType(baseRideTypeId) {
    baseRideTypes.value = baseRideTypes.value.filter(x => x.id != baseRideTypeId);
    baseRideTypes.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredBaseRideTypes.value = paginateArray(baseRideTypes.value, perPage.value, currentPage.value)
  }

  const fetchBaseRideTypes = (ctx) => {
    // if(baseRideTypes.value?.length) {
    //   return;
    // }
    useJwt.axiosIns.get(routes.BASE_RIDE_TYPE)
      .then(({data}) => {
        baseRideTypes.value = data.sort((a, b) => a.updatedAt - b.updatedAt);
        filteredBaseRideTypes.value = paginateArray(baseRideTypes.value, perPage.value, currentPage.value)
        totalRideTypes.value = data.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching base ride types',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchBaseRideTypes,
    tableColumns,
    perPage,
    currentPage,
    totalRideTypes,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBaseRideTypeTable,

    setBaseRideType,
    removeBaseRideType,
    refetchData,
    baseRideTypes: computed(() => filteredBaseRideTypes.value)
  }
}
