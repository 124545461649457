import { ref, watch, computed, reactive } from '@vue/composition-api'
import store, {globalStore} from '@/store'
import { title } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const rideTypes = ref([])
const filteredRideTypes = ref([])

export default function useRideType() {
  // Use toast
  const toast = useToast()

  const refRideTypeTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, stickyColumn: true },
    { key: 'name', sortable: true },
    { key: 'baseFare', label: 'Commission (in %)', sortable: true },
    { key: 'baseType.name', label: 'Base Ride', sortable: true },
    { key: 'actions',  },
  ]
  const perPage = ref(10)
  const totalRideTypes = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)


  const dataMeta = computed(() => {
    const localItemsCount = refRideTypeTable.value ? refRideTypeTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRideTypes.value,
    }
  })

  const refetchData = () => {
    refRideTypeTable.value.refresh()
  }
 
  const refreshData = () => {
    rideTypes.value = []
    fetchRideTypes()
  }

  watch([currentPage, perPage, searchQuery], () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = rideTypes.value.filter(
    rideType => rideType.name.toLowerCase().includes(queryLowered))
    
    filteredRideTypes.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalRideTypes.value = filteredData.length
    refetchData()
  })

  function setRideType(rideType) {
    rideTypes.value = rideTypes.value.filter(x => x.id != rideType.id);
    rideTypes.value.push(rideType);
    rideTypes.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredRideTypes.value = paginateArray(rideTypes.value, perPage.value, currentPage.value)
  }

  function removeRideType(rideTypeId) {
    rideTypes.value = rideTypes.value.filter(x => x.id != rideTypeId);
    rideTypes.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredRideTypes.value = paginateArray(rideTypes.value, perPage.value, currentPage.value)
  }

  const fetchRideTypes = (ctx) => {
    if(rideTypes.value?.length) {
      return;
    }
    useJwt.axiosIns.get(routes.RIDE_TYPE)
      .then(({data}) => {
        rideTypes.value = data.sort((a, b) => a.updatedAt - b.updatedAt);
        filteredRideTypes.value = paginateArray(rideTypes.value, perPage.value, currentPage.value)
        totalRideTypes.value = data.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching rideTypes',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const updateRideType = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.put(routes.RIDE_TYPE+`/${data.id}`, data)
        .then(({ data }) => {
          setRideType(data)
          toast({
            component: ToastificationContent,
            props: {
              title: `Update Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  }

  return {
    fetchRideTypes,
    updateRideType,
    tableColumns,
    perPage,
    currentPage,
    totalRideTypes,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRideTypeTable,

    setRideType,
    removeRideType,
    refetchData,
    refreshData,
    rideTypes: computed(() => filteredRideTypes.value)
  }
}
