<template>
  <b-sidebar
    id="add-new-rate-sidebar"
    :visible="isAddNewRateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="onFormToggle"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{isEdit ? 'Update Rate' : 'Add New Rate'}}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Ride Type -->
          <validation-provider
            #default="validationContext"
            name="Ride Type"
            rules="required"
          >
            <b-form-group
              label="Ride Type"
              label-for="ride-type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="rateData.baseType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="rideTypeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="ride-type"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- City -->
          <validation-provider name="City" #default="validationContext">
            <b-form-group
              label="City"
              label-for="city"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="rateData.city"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="cityOptions"
                :reduce="(val) => val.value"
                :clearable="true"
                input-id="city"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Insurance -->
          <validation-provider
            #default="validationContext"
            name="Insurance"
            rules="required"
          >
            <b-form-group label="Insurance" label-for="insurance">
              <b-form-input
                id="insurance"
                v-model="rateData.insuranceRate"
                type="number"
                min="0"
                step="0.001"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0.0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Rate per km -->
          <validation-provider
            #default="validationContext"
            name="Rate/KM"
            rules="required"
          >
            <b-form-group label="Rate/KM" label-for="rate-per-km">
              <b-form-input
                id="rate-per-km"
                v-model="rateData.ratePerKm"
                type="number"
                min="0"
                step="0.001"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0.0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Rate per min -->
          <validation-provider
            #default="validationContext"
            name="Rate/Min"
            rules="required"
          >
            <b-form-group label="Rate/Min" label-for="rate-per-min">
              <b-form-input
                id="rate-per-min"
                v-model="rateData.ratePerMin"
                type="number"
                min="0"
                step="0.001"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0.0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Start price -->
          <validation-provider
            #default="validationContext"
            name="Base Price"
            rules="required"
          >
            <b-form-group label="Base Price" label-for="start-price">
              <b-form-input
                id="start-price"
                v-model="rateData.startPrice"
                type="number"
                min="0"
                step="0.001"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0.0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Min price -->
          <validation-provider
            #default="validationContext"
            name="Min Price"
            rules="required"
          >
            <b-form-group label="Min Price" label-for="minimum-price">
              <b-form-input
                id="minimum-price"
                v-model="rateData.minimumPrice"
                type="number"
                min="0"
                step="0.001"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0.0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Waiting Rate -->
          <validation-provider
            #default="validationContext"
            name="Waiting Rate"
            rules="required"
          >
            <b-form-group label="Waiting Rate" label-for="waiting-rate">
              <b-form-input
                id="waiting-rate"
                v-model="rateData.waitingRate"
                type="number"
                min="0"
                step="0.001"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0.0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Airport Rate -->
          <validation-provider
            #default="validationContext"
            name="Airport Rate"
            rules="required"
          >
            <b-form-group label="Airport Rate" label-for="airport-rate">
              <b-form-input
                id="airport-rate"
                v-model="rateData.airportRate"
                type="number"
                min="0"
                step="0.001"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0.0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Booking Rate -->
          <validation-provider
            #default="validationContext"
            name="Booking Rate"
            rules="required"
          >
            <b-form-group label="Booking Rate" label-for="booking-rate">
              <b-form-input
                id="booking-rate"
                v-model="rateData.bookingFee"
                type="number"
                min="0"
                step="0.001"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0.0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Surge Charge -->
          <validation-provider
            #default="validationContext"
            name="Surge Charge"
            rules="required"
          >
            <b-form-group label="Surge Charge" label-for="surge-charge">
              <b-form-input
                id="surge-charge"
                v-model="rateData.surgeCharge"
                type="number"
                min="0"
                step="0.001"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0.0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Union Charge -->
          <validation-provider
            #default="validationContext"
            name="Union Charge"
            rules="required"
          >
            <b-form-group label="Union Charge" label-for="union-charge">
              <b-form-input
                id="union-charge"
                v-model="rateData.unionCharge"
                type="number"
                min="0"
                step="0.001"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0.0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{isEdit ? 'Update' : 'Add'}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { onMounted, ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import useRate from "../useRate";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewRateSidebarActive",
    event: "update:is-add-new-driver-sidebar-active",
  },
  props: {
    isAddNewRateSidebarActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    rateToUpdate: {
      type: Object,
      required: false,
    },
    rideTypeOptions: {
      type: Array,
      required: true,
    },
    cityOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
  
    const toast = useToast();

    let blankRateData = {
      id: null,
      city: null,
      rideType: null,
      baseType: null,
      insuranceRate: 0,
      ratePerKm: 0,
      ratePerMin: 0,
      startPrice: 0,
      minimumPrice: 0,
      waitingRate: 0,
      airportRate: 0,
      bookingFee: 0,
      surgeCharge: 0,
      unionCharge: 0,
    };

    const rateData = ref(JSON.parse(JSON.stringify(blankRateData)));
    const resetrateData = () => {
      rateData.value = JSON.parse(JSON.stringify(blankRateData));
    };

    const onSubmit = () => {
      if (props.isEdit) {
        dispatchUpdateRate();
      } else {
        dispatchAddRate();
      }
    };

    const dispatchAddRate = () => {
      store
        .dispatch("rider/addRate", rateData.value)
        .then((rate) => {
          setRate(rate);
          emit("refetch-data");
          emit("update:is-add-new-rate-sidebar-active", false);
           toast({
            component: ToastificationContent,
            props: {
              title: `Record added Successfully`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
        .catch((message) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const dispatchUpdateRate = () => {
      store
        .dispatch("rider/editRate", rateData.value)
        .then((rate) => {
          setRate(rate);
           emit("refetch-data");
          emit("update:is-add-new-rate-sidebar-active", false);
           toast({
            component: ToastificationContent,
            props: {
              title: `Update Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
        .catch((message) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const onFormToggle = (value) => {
        emit('update:is-add-new-rate-sidebar-active', value)
        if(value) {
            if(props.isEdit) {
                blankRateData = {...props.rateToUpdate};
                blankRateData.rideType = props.rateToUpdate?.rideType?.id;
                blankRateData.baseType = props.rateToUpdate?.baseType?.id;
                rateData.value = blankRateData
            } else {
                props.isEdit = false;
                blankRateData = {
                    id: null,
                    city: null,
                    rideType: null,
                    insuranceRate: 0,
                    ratePerKm: 0,
                    ratePerMin: 0,
                    startPrice: 0,
                    minimumPrice: 0,
                    waitingRate: 0,
                    airportRate: 0,
                    bookingFee: 0,
                    surgeCharge: 0,
                    unionCharge: 0,
                }; 
                rateData.value = blankRateData
            }
        }
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetrateData);

    const { setRate } = useRate();

    return {
      rateData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      onFormToggle
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-driver-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
